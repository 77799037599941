.carousel .thumb img {
  width: 100% !important;
  height: 100% !important;
}

.carousel .control-dots .dot {
  background-color: rgb(0, 0, 0);
}

.carousel .slide img {
  max-height: 420px;
  width: auto;
}
.carousel .carousel-status {
  color: rgb(10, 10, 10);
  font-size: small;
  margin-top: -25px;
  text-shadow: none;
  font-weight: 700;
  font-family: "Noto Sans", sans-serif;
}
.slider-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.carousel.carousel-slider {
  overflow: inherit;
}

.carousel .control-next.control-arrow,
.carousel .control-next.control-arrow:hover {
  background-color: transparent;
}

.carousel .control-prev.control-arrow,
.carousel .control-prev.control-arrow:hover {
  background-color: transparent;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  opacity: 1;
}

.carousel .control-next.control-arrow:before {
  content: "";
  border: solid #95a8d7;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.carousel .control-prev.control-arrow:before {
  content: "";
  border: solid #95a8d7;
  border-width: 0 8px 8px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
