body {
  margin: 0;
  scrollbar-width: auto;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.Mui-disabled {
  cursor: not-allowed !important;
}
.MuiStepConnector-root.Mui-disabled,
.MuiStepLabel-label.Mui-disabled,
.MuiStepLabel-root.Mui-disabled {
  cursor: default !important;
}
.MuiButtonBase-root.MuiStepButton-root.Mui-disabled {
  pointer-events: all !important;
}
textarea {
  font-family: "Noto Sans", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;
  padding: 14px;
  color: #696f88;
}
textarea:hover {
  outline: 1px solid #000;
  border: 0;
}
textarea:focus,
textarea:focus-visible {
  outline: 2px solid #0071cd;
  border: 0;
}
h6 {
  margin-bottom: 5px !important;
}
